import React, { useState, useCallback, Suspense, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import './styles/App.css'
import ReactGA from 'react-ga4'

// Components
import Layout from './components/Layout'
// const Day10 = React.lazy(() => import('./components/Day10'))
const EventInfo = React.lazy(() => import('./components/EventInfo'))
const Calendar = React.lazy(() => import('./components/Calendar'))
const MyMap = React.lazy(() => import('./components/Map'))
const Story = React.lazy(() => import('./components/Story'))
const Exhibition = React.lazy(() => import('./components/Exhibition'))
const Food = React.lazy(() => import('./components/Food'))
const Footer = React.lazy(() => import('./components/Footer'))

const TRACKING_ID = 'G-QLSNK2J470'

function App() {
  const { t, i18n } = useTranslation()
  const [language, setLanguage] = useState('pl')
  const [justInfo, setJustInfo] = useState(false)
  const [seeArchival, setSeeArchival] = useState(false)

  const changeLanguage = useCallback(
    (lng) => {
      i18n.changeLanguage(lng)
    },
    [i18n]
  )

  // Import JSON data
  const opening_party = require('./assets/opening_party.json')
  const jinhale_party = require('./assets/jinhale_party.json')
  const story_impreza = require('./assets/story_impreza.json')
  const exhibition = require('./assets/exhibition.json')
  const closing_party = require('./assets/closing_party.json')
  const friday_party = require('./assets/friday_party.json')

  const toggleLanguage = useCallback(() => {
    if (language === 'pl') {
      setLanguage('en')
      changeLanguage('en')
    } else {
      setLanguage('pl')
      changeLanguage('pl')
    }
    ReactGA.event({
      category: 'Button',
      action: 'Set language',
      label: language
    })
  }, [language, changeLanguage])

  const toggleJustInfo = useCallback(() => {
    setJustInfo((prevJustInfo) => !prevJustInfo)
    ReactGA.event({
      category: 'Button',
      action: 'Toggle calendar view'
    })
  }, [])

  useEffect(() => {
    ReactGA.initialize(TRACKING_ID)
    ReactGA.send('pageview')
  })

  return (
    <main className='main-content'>
      {/* Toggle buttons */}
      <div className='toggle-buttons' style={{ position: 'fixed', bottom: '0', right: '0', zIndex: '1000' }}>
        <button id='language' title='Toggle language' onClick={toggleLanguage}>
          {language === 'pl' ? '🇵🇱' : '🇬🇧'}
        </button>
        <button id='just-info' title='Toggle photos, videos and animations' onClick={toggleJustInfo}>
          {justInfo ? '📱' : '🗓️'}
        </button>
        <button
          id='see-archival'
          className='text-button'
          title='See archival content'
          onClick={() => setSeeArchival((prev) => !prev)}>
          {seeArchival ? t('turn_off_past') : t('turn_on_past')}
        </button>
      </div>

      {/* Navbar */}
      <header className='header'>
        <h1>
          <a href='/'>
            <img alt='Matki Boskiej Zielnej 4.0' src={require('./assets/logoMBZ.png')} />
          </a>
        </h1>
        <nav>
          <span className='nav-item'>
            <button onClick={toggleJustInfo}>
              <p className='uppercase'>{t('calendar')}</p>
            </button>
          </span>
          <div className='nav-item dropdown dropdown-hover'>
            <div tabIndex={0} role='button' className='btn btn-ghost rounded-btn uppercase'>
              <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='currentColor' className='size-6'>
                <path
                  fillRule='evenodd'
                  d='M3 6.75A.75.75 0 0 1 3.75 6h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 6.75ZM3 12a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 12Zm0 5.25a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75a.75.75 0 0 1-.75-.75Z'
                  clipRule='evenodd'
                />
              </svg>
              {t('events')}
            </div>
            <ul
              tabIndex={0}
              className='menu dropdown-content'
              onClick={() => {
                ReactGA.event({
                  category: 'Button',
                  action: 'Facebook events click'
                })
              }}>
              {seeArchival ? (
                <li className='nav-item'>
                  <a href='https://www.facebook.com/events/1555904798322181/'>10.08 {t('opening_party')}</a>
                </li>
              ) : (
                ''
              )}
              <li className='nav-item'>
                <a href='https://www.facebook.com/events/1135531537778082/'>10-15.08 {t('exhibition')}</a>
              </li>
              {seeArchival ? (
                <li className='nav-item'>
                  <a href='https://www.facebook.com/events/488842260435973/'>14.08 {t('ambient_lives')}</a>
                </li>
              ) : (
                ''
              )}
              <li className='nav-item'>
                <a href='https://www.facebook.com/events/840093994469024/'>15.08 {t('closing_party')}</a>
              </li>
              <li className='nav-item'>
                <a href='https://www.facebook.com/events/1563992621212878/'>16.08 {t('friday_party')}</a>
              </li>
            </ul>
          </div>
        </nav>
      </header>

      {/* Content */}
      {justInfo ? (
        <>
          <div className='calendar-layout'>
            <button onClick={toggleJustInfo} className='btn uppercase w-full'>
              <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='currentColor' className='size-6'>
                <path
                  fillRule='evenodd'
                  d='M11.03 3.97a.75.75 0 0 1 0 1.06l-6.22 6.22H21a.75.75 0 0 1 0 1.5H4.81l6.22 6.22a.75.75 0 1 1-1.06 1.06l-7.5-7.5a.75.75 0 0 1 0-1.06l7.5-7.5a.75.75 0 0 1 1.06 0Z'
                  clipRule='evenodd'
                />
              </svg>
              {t('back')}
            </button>
            <Calendar />
          </div>
        </>
      ) : (
        <Suspense fallback={<div>Loading...</div>}>
          <Layout>
            {seeArchival ? <Story stories={story_impreza} folder='impreza' /> : ''}
            <Calendar />
            {seeArchival ? <h1 className='break-title'>{t('14-08')}</h1> : ''}
            {seeArchival ? <EventInfo eventData={jinhale_party} /> : ''}
            <h1 className='break-title'>{t('15-08')}</h1>
            <EventInfo eventData={closing_party} />
            <h1 className='break-title'>{t('16-08')}</h1>
            <EventInfo eventData={friday_party} />
            <h1 className='break-title'>{t('10-08')}</h1>
            <Exhibition eventData={exhibition} seeArchival={seeArchival} />
            {seeArchival ? <Food /> : ''}
            {seeArchival ? <EventInfo eventData={opening_party} /> : ''}
            <MyMap />
          </Layout>
        </Suspense>
      )}

      {/* Footer */}
      {justInfo ? '' : <Footer />}
    </main>
  )
}

export default App
