import React, { Suspense } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import Loader from './Loader'

function Layout({ children }) {
  return (
    <div className='bento-container'>
      {children.map((_, i) =>
        _.type === 'h1' ? (
          <div key={i} className='bento-card col-span-3'>
            {_}
          </div>
        ) : _ ? (
          <div key={i} className='bento-card lg:col-span-3'>
            <Suspense fallback={<Loader />}>
              <ErrorBoundary fallback={<Loader />}>{_}</ErrorBoundary>
            </Suspense>
          </div>
        ) : (
          ''
        )
      )}
    </div>
  )
}

export default Layout
